<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div>
      <b-row>
        <b-col cols="12">
          <b-card>

            <h4>اطلاعات کلی</h4>

            <!-- Media -->
            <b-media class="my-2">
              <template >
                <b-avatar
                    ref="previewEl"
                    :src="base64ImageSrc"
                    size="300px"
                    rounded
                />
              </template>
              <div class="d-flex flex-wrap mt-1">
                <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                >
                  <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="makeBase64Pic"
                  >
                  <span class="d-none d-sm-inline">+ عکس</span>
                  <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
                <b-button
                    variant="outline-secondary"
                    class="ml-1"
                    @click="deleteProductPic()"
                >
                  <span class="d-none d-sm-inline">حذف</span>
                  <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-media>

            <div>

              <b-form>
                <b-row>

                  <!-- Field:  Title -->
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="عنوان"
                        label-for="Title"
                    >
                      <b-form-input
                          id="Title"
                          v-model="defaultData.title"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: isFree -->
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="وضعیت"
                        label-for="user-status"
                    >
                      <v-select
                          v-model="defaultData.isFree"
                          :options="isFreeOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="user-status"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field:  price -->
                  <b-col
                      v-if="!defaultData.isFree"
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="قیمت (تومان)"
                        label-for="price"
                    >
                      <b-form-input
                          id="price"
                          v-model="defaultData.price"
                          :aria-disabled="!defaultData.isFree"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: isAvailable -->
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="موجودیت"
                        label-for="isAvailable"
                    >
                      <v-select
                          v-model="defaultData.available"
                          :options="isAvailableOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="isAvailable"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field:  subtitle -->
                  <b-col
                      cols="12"
                  >
                    <b-form-group
                        label="سوتیتر"
                        label-for="subtitle"
                    >
                      <b-form-input
                          id="subtitle"
                          v-model="defaultData.subtitle"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>

              <b-button
                  variant="primary"
                  class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="createProduct"
              >
                ثبت
              </b-button>

            </div>
          </b-card>

        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {ProductCategoryGetAllRequest} from "@/libs/Api/Product";
import {ProductCategoryPropertyGetByCategoryIdRequest} from "@/libs/Api/Product";
import {ProductCreateRequest} from "@/libs/Api/Product";
import {ProductCourseGetAllRequest} from "@/libs/Api/Product";
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ProductCategoryGetAllProductCategoriesRequest} from "@/libs/Api/Product";
import {ProductCategoryGetAllCourseCategoriesRequest} from "@/libs/Api/Product";
export default {
  title:"ساخت پست آموزشی - پنل ادمین مکس ",
  name: "CreateEducationalPost",
  components:{
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
  },
  data(){
    return{
      overlay:false,
      propertiesData:[],
      defaultData:{
        isDeleted: false,
        productId: 0,
        title: "",
        description: "",
        productCategoryId: 0,
        courseScheduleId: 0,
        courseId: null,
        price: "",
        isFree: true,
        type: 5,
        imageFile: "",
        fileData: null,
        trailerFile:'',
        subtitle:'',
        viewCounts: 0,
        properties: [],
        isAvailable:true,
      },
      isFreeOptions:[
        {label : 'رایگان' , value : true},
        {label : 'پولی' , value : false}
      ],
      types:[
        {label : 'دوره' , value : 1},
        {label : 'فیلم' , value : 2},
        {label : 'فایل' , value : 3},
        {label : 'پادکست' , value : 4},
      ],
      isAvailableOptions:[
        {label : 'موجود' , value : true},
        {label : 'ناموجود' , value : false}
      ],
      base64ImageSrc:null,
      productCategories:null,
      courseCategories:null,
      categoryProperties:null,
      courses:null,
    }
  },
  computed:{
    defaultDataProductCategoryId(){
      return this.defaultData.productCategoryId;
    },
    defaultDataProductType(){
      return this.defaultData.type;
    },
    defaultDataImageFile(){
      return this.defaultData.imageFile
    }
  },
  watch:{
    defaultDataProductCategoryId(nv,ov){
      this.getCategoryProperties(nv)
    },
  },
  methods:{
    async createProduct(){
      let  _this = this;
      _this.overlay = true

      let productCreateRequest = new ProductCreateRequest(_this);
      productCreateRequest.setParams(_this.defaultData);
      await productCreateRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.$router.push({name:'apps-educational-post-list'})
      }, function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e){
      const _this = this;
      let file = e.target.files[0]
      _this.base64ImageSrc = URL.createObjectURL(file)
      const result = await _this.readAsDataURL(file);
      _this.defaultData.imageFile = result;
    },
    async makeBase64(e){
      const _this = this;
      let file = e.target.files[0]
      const result = await _this.readAsDataURL(file);
      _this.defaultData.trailerFile = result;
    },
    deleteProductPic(){
      this.defaultData.imageFile = '';
      this.base64ImageSrc = null
    },
  },
}
</script>

<style scoped>

</style>